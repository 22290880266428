#program-title{
    text-align: center;
    font-weight: lighter;
    font-style: italic;
    border: none;
    border-bottom: 1px;
    background-color: #F3F3F3;
    font-size: 32px;
    outline: none;
    width: 100%;
    resize: none;
    font-family:  'Comfortaa',
    'cursive';
}

#program-subtitle{
    text-align: center;
    font-weight: bold;
}

#program-logo{
    max-height: 100px;
    z-index: var(--headers-index);
}


.textfield:focus{
    border: 0;
    border-bottom: 2px solid #555;
}

.divTextfield{
    /*padding: 20px;*/
    outline: 0px;
}