.Headers-logo{
    max-height: 60px;
    z-index: var(--headers-index);
    width: inherit;
    max-width: 200px;
}
.Headers-title {
    text-align: center;
    width: fit-content;
    position: relative;
    z-index: var(--headers-index);

}
.Headers-title::after {
    content: '';
    transform-origin: 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: .4em;
    background: #ff8500;
    z-index: -1;
    opacity: 0.8;
}
.Headers{
    position: fixed;
    background-color: white;
    padding-top: 20px;
    --headers-index: 1300;
    z-index: var(--headers-index);
}
