.alinea{
    margin-left: 20px;
}
.alinea2{
    margin-left: 40px;
}
.alinea3 {
    margin-left: 60px;
}

.flex-none {
    flex: none;
}

#add-module {
    border: none;
    border-radius: 20px;
    padding-left: 7px;
    padding-right: 7px;
    color: gray;
    margin-bottom: 10px;
    margin-top: 15px;
}

#add-module:hover {
    background-color: #ffb666; /* On modifie la couleur du fond au passage de la souris */
    color: white;
}
.help-add-module {
    background-color: #ffb666;
    font-style: italic;
    margin-bottom: 10px;
    margin-top: 10px;
    size: auto;
    padding-right: 10px;
    padding-left: 10px;
    display: table;
    border-radius: 50px;
}
.border{
    position: relative
}
.border-active{
    position: relative
}
.border:before{
    content: "";
    width: 0;
    height: 0;
    border-left: #66d2e8 5px solid;
    transition: height 0.6s ease-in-out;
    position: absolute;
    left: -5px;
    top: 0;
}
.border-active:before{
    content: "";
    width: 0;
    height: 100%;
    border-left: #66d2e8 5px solid;
    transition: height 0.6s ease-in-out;
    position: absolute;
    left: -5px;
    top: 0;
}