.flex-container {
    display: flex;
}
.module:hover .delete{
    visibility: visible;
}

.flex-child{
    flex: 1;
}

#title{
    text-decoration: underline;
}
li{
    margin: 4px;
}
.delete{
    margin: auto 0;
    visibility: hidden;
}
.grid-container {
    display: grid;
}
.grab-cursor{
    cursor: grab;
}