.flex-container {
    display: flex;
}
.element:hover .delete{
        visibility: visible;
}

.flex-child{
    flex: 1;
}

#title{
    text-decoration: underline;
}
.puce{
    flex: none;
    margin-right: 16px;
    font-size:24px
}

#add-element {
    border: none;
    border-radius: 20px;
    padding-left: 7px;
    padding-right: 7px;
    color: gray;
}

#add-element:hover {
    background-color: #66d2e8; /* On modifie la couleur du fond au passage de la souris */
    color: white;
}

li{
    margin: 4px;
}

.required{
    margin-left: 32px;
    list-style-type: circle;
}

.delete{
    visibility: hidden;
}

.no-element{
    font-style: italic;
    color: darkgray;
    margin: 9px;
}
.bold{
    font-weight: bold;
}

.drop{
    border: #282c34 solid 1px;
    border-radius: 5px;
}
.selectedSection{
    position: relative
}

.selectedSection:after{
    content: "";
    width: 100%;
    height: 0;
    border-bottom: #66d2e8 5px solid;

    position: absolute;
    left: -5px;
    bottom: -5px;
}