.button{
    display: inline;
}
.padding-x{
    padding-left: 10px;
    padding-right: 10px;
}
.display-block{
    display: block;
}
.border-radius{
    border-radius: 0 20px 0 0;
    height: 90px;

}
.drawerButton{
    position: fixed;
    margin-top: 56px;
    z-index: 1400;
    margin-left: -29px;
}
.toolBarDiv{
    height:130px;
}
.display-inlineflex{
    display: inline-flex;
}

/* width */
::-webkit-scrollbar {
    width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #66d2e8;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #00B4D8;
}

/* Tooltip container */
.tooltip {
    margin: 10px;
}
.help{
    color: #ff8500;
}
/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}