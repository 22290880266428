.App-logo {
    height: 40vmin;
}

.App-logo:hover {
    animation: App-logo-spin infinite 0.1s linear;
}


.App-header {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
