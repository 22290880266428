.textfield{
    border: none;
    border-bottom: 1px;
    background-color: #F3F3F3;
    font-size: 16px;
    outline: none;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}

.text{
    padding: 0;
}

.textfield:focus{
    border: 0;
    border-bottom: 2px solid #555;
}

.divTextfield{
    /*padding: 20px;*/
    outline: 0;
}
.margin-auto{
    margin: auto;
}
.w100{
    width: 100%;
}
.grab-cursor{
    cursor: grab;
}