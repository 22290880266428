.footer{
    padding: 20px;
}

.footer{
    color: primary;
}

#correspondance{
    text-decoration: underline;
    font-weight: bold;
}

.footerInfoSociete{
    text-align: center;
    font-size: small;
    font-style: oblique;
}
