.type{
    font-style: italic;
}


.card{
    transition: all .3s  ease-in-out;

}
.card:hover {
    transform: scale(1.05);
    cursor: pointer;
}
