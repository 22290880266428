.flex-container {
    display: flex;
}

.flex-child{
    flex: 1;
}

#article-name{
    font-weight: 600;
    font-style: normal;
}

#article-content{
    font-style: oblique;
}